import useI18n from "@/hooks/useI18n";
import { ListGroup, ListItem } from "@gisce/oficina-virtual-components"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";
import Settings from "../../../settings";
import { useSelector } from "react-redux";
import {
  Person,
  PersonAdd,
  AddBox,
  Message,
	MapsHomeWork,
	Receipt,
	Equalizer
} from "@mui/icons-material";
import { RootState } from "@/store";

export const GroupedSideBar = () => {
	const { t } = useTranslation();
	const { getContractationURL } = useI18n();
	const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const handleContractAction = (type: string) => {}

	const ContractListGroup = <ListGroup
		icon={<MapsHomeWork />}
	 	titleLabel={t('common:text.altHome_contract_title')}
	>
		<ListItem 
			label={t('common:text.altHome_contract_create')}
			onClick={() => navigate(getContractationURL())}
			selected={location.pathname === getContractationURL()}
		/>
		<ListItem 
			label={t('common:text.altHome_contract_view')}
			onClick={() => navigate(t('common:url.contracts'))}
			selected={location.pathname === t('common:url.contracts')}
		/>
		<ListItem 
			label={t('common:text.altHome_contract_download')}
			onClick={() => handleContractAction("download")}
		/>
		<ListItem 
			label={t('common:text.altHome_contract_power')}
			onClick={() => handleContractAction("powChange")}
		/>
		<ListItem 
			label={t('common:text.altHome_contract_iban')}
			onClick={() => handleContractAction("ibanChange")}
		/>
		{Settings.features.notificacionAddressChange && (
			<ListItem 
				label={t('common:text.altHome_contract_contact')}
				onClick={() => handleContractAction("notifAddrChange")}
			/>
		)}
		{Settings.features.noCesionSips && (
			<ListItem 
				label={t('common:text.altHome_contract_sips')}
				onClick={() => handleContractAction("noCesionSips")}
			/>
		)}
	</ListGroup>

	const InvoicesListGroup = <ListGroup 
		icon={<Receipt />}
		titleLabel={t('common:text.altHome_invoices_title')}
	>
		<ListItem 
			label={t('common:text.altHome_invoices_view')}
			onClick={() => navigate(t('common:url.invoices'))}
			selected={location.pathname === t('common:url.invoices')}
		/>
		<ListItem 
			label={t('common:text.altHome_invoices_download')}
			onClick={() => navigate(t('common:url.invoices'))}
		/>
		{Settings?.invoices?.enablePayment && (
			<ListItem 
				label={t('common:text.altHome_invoices_payonline')}
				onClick={() => navigate(t('common:url.invoices'))}
			/>
		)}
	</ListGroup>

	const ConsumptionListGroup = <ListGroup 
		icon={<Equalizer />}
		titleLabel={t('common:text.altHome_consumption_title')}
	>
		<ListItem 
			label={t('common:text.altHome_consumption_view')}
			onClick={() => handleContractAction("consumption")}
		/>
	</ListGroup>

	return <>
		{ContractListGroup}
		{InvoicesListGroup}
		{ConsumptionListGroup}

		{!isAuthenticated && <>
			{!Settings.features?.oidc && <ListItem
				icon={<Person />}
				label={t('common:text.login_title')}
				onClick={() => navigate(t('common:url.login'))}
			/>}
			{Settings.features?.signup && <ListItem
				icon={<PersonAdd />}
				label={t('common:text.login_view_button_activate')}
				onClick={() => navigate(t('common:url.activate'))}
				selected={location.pathname === t('common:url.activate')}
			/>}
			{Settings.features?.sidebarContract && <ListItem
				icon={<AddBox />}
				label={t('common:text.new_customer_title')}
				onClick={() => navigate(getContractationURL())}
				selected={location.pathname === getContractationURL()}
			/>}
			{Settings.features?.unsignedContact && 
				Settings.features?.contact && <ListItem 
				icon={<Message />}
				label={t('common:text.contact_title')}
				onClick={() => navigate(t('common:url.unsigned_contact'))}
				selected={location.pathname === t('common:url.unsigned_contact')}
			/>}
		</>}
	</>
}
