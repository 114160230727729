import { NewsletterRead } from "@/types/models/services/newsletter";
import { HelpOutline } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Alert,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  styled,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
  useTheme,
  AlertTitle,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  newsletter: NewsletterRead;
  subscribed: boolean;
  exported: boolean;
  checked: boolean;
  onCardToggle: (newsletterId: number) => void;
}

const AlertReadyTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
  },
}));

const ProtectedAlert: FC = () => {
  const { t } = useTranslation();

  return <Alert severity="warning" sx={{boxShadow: 4}}>
    <AlertTitle>{t("common:text.newsletter_desubscription_unavailable_title")}</AlertTitle>
    {t("common:text.newsletter_desubscription_unavailable_content")}
  </Alert>
}

const NewsletterCard: FC<Props> = ({
  newsletter,
  subscribed,
  checked,
  exported,
  onCardToggle
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const locked = exported && subscribed;

  return (
    <AlertReadyTooltip 
      title={locked ? <ProtectedAlert/> : ""} 
      sx={{backgroundColor: "transparent"}}
      placement="top"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -30],
              },
            },
          ],
        },
      }}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <Card sx={{
        filter: locked ? "grayscale(60%)" : "inherit"
      }}>
        <CardActionArea
          disableRipple={true}
          sx={{
            border: `2px solid ${checked ? theme.palette.secondary.main : grey[200]}`,
            cursor: locked ? "inherit" : "pointer",
          }}
          onClick={() => (!locked) && onCardToggle(newsletter.id)}
        >
          <CardContent>
            <Stack direction={"row"} justifyContent={locked ? "flex-end" : "space-between"} alignItems={"center"}>
              {((checked) ? <CheckCircleIcon color="secondary"/>: <RadioButtonUncheckedIcon/>) }
              <Typography variant="overline" component="p" sx={{textAlign: "right"}} color={"primary"}>
                { (subscribed) ? t("text.newsletter_subscribtion_actived"): t("text.newsletter_subscribtion_desactived")}
              </Typography>
            </Stack>
            <Typography variant="h6" component="div" textAlign={"left"}>
              {newsletter.name}
            </Typography>
            <Typography variant="body2" component="p" sx={{textAlign: "left"}} color="textSecondary">
              {newsletter.newsletterDescription}
            </Typography>
          </CardContent>

        </CardActionArea>
      </Card>
    </AlertReadyTooltip>
  );
};

export default NewsletterCard;
