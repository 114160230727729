import { NewsletterRead } from "@/types/models/services/newsletter";
import {
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { FC } from "react";
import NewsletterCard from "./NewsletterCard";
import { useTranslation } from "react-i18next";

interface Props {
  newsletters: NewsletterRead[];
  value: NewsletterRead["id"][];
  onChange: (value: NewsletterRead["id"][]) => void;
}
const NewslettersSelector: FC<Props> = ({
  value, onChange, newsletters
}) => {
  const { t } = useTranslation();

  const handleCardToggle = (newsletterId: NewsletterRead["id"]) => {
    onChange(value.includes(newsletterId)
        ? value.filter((id) => id !== newsletterId) // Desmarcar
        : [...value, newsletterId] // Marcar
    );
  };

  const isAllSelectableSelected = () => {
    const selectable = newsletters.filter(item => !item.newsletterExported || (item.newsletterExported && !item.subscribed));
    const currentSelectedSelectable = selectable.filter(item => value.includes(item.id));
    return currentSelectedSelectable.length >= selectable.length
  }

  const handleToggleAll = () => {
    if (isAllSelectableSelected()) {
      onChange(newsletters.filter(item => item.newsletterExported && item.subscribed).map((newsletter) => newsletter.id)); // Desmarcar totes les no exportades i/o no subscrites
    } else {
      onChange(newsletters.map((newsletter) => newsletter.id)); // Seleccionar totes
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Typography variant="h6" textAlign={"left"}>{t("text.newsletter_newsletters")}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={handleToggleAll}
            >
            {
              isAllSelectableSelected()
                ? t("text.newsletter_deselect_all") : t("text.newsletter_select_all")
            }
          </Button>
        </Grid>
        {newsletters.map((newsletter) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={newsletter.id}>
            <NewsletterCard
              newsletter={newsletter}
              checked={value.includes(newsletter.id)}
              subscribed={newsletter.subscribed}
              exported={newsletter.newsletterExported}
              onCardToggle={handleCardToggle}/>
          </Grid>
        ))}
      </Grid>
      </>
  );
};

export default NewslettersSelector;
