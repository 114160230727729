import { useEffect } from "react";
import useI18n from "@/hooks/useI18n";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ILanguageProvider {
	path: string;
  children: React.ReactNode;
}

// This provider checks the url and the current cookies for authentication
export const LanguageProvider = ({
	path,
  children
}: ILanguageProvider) => {
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
  const { currentLang } = useI18n();

  useEffect(() => {
    // Ensure path is provided and exists in translations
    if (path && i18n.exists(path)) {
      // Replace template placeholders with route params
      const translatedPath = t(path.replace("{{", ":").replace("}}", ""), params);

      // Append query params to maintain them
      const searchParams = location.search ? location.search : "";
      const fullPath = `${translatedPath}${searchParams}`;

      // Avoid unnecessary navigation
      if (fullPath !== `${location.pathname}${location.search}`) {
        navigate(fullPath, { replace: true }); // Use `replace` to avoid filling history
      }
    }
  }, [currentLang.value, i18n, location.pathname, location.search, navigate, params, path, t]);

  return children
}
