import { RootState } from "@/store";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingView from "@/components/LoadingView";

const RedirectInvoices: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthenticating = useSelector((store: RootState) => store.auth.isAuthenticating);
  const redirect = useSelector((store: RootState) => store.auth.redirect);
  const isRedirectedAfterLogin = useSelector((store: RootState) => store.auth.isRedirectedAfterLogin);

  useEffect(() => {
    //Quan fem el dashboard potser podem canviar això

    // Serveix per redirigir quan 
    if (!isAuthenticating) {
      if (redirect && isRedirectedAfterLogin) {
        navigate(t("common:url.invoices"));
      } else {
        navigate(t("common:url.invoices"));
      }
    }
  }, [isAuthenticating]);

  return <LoadingView />;
}

export default RedirectInvoices;