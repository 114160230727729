import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import {
  NewsletterRead,
  OfuscatedId,
  SubscriberEmails,
  SubscriberId,
} from "@/types/models/services/newsletter";
import { NewsletterPageState, SelectedNewsletters } from "./useNewsletterPage";

interface Props {
  subscriberId: OfuscatedId | SubscriberId | null;
  emails: SubscriberEmails;
  newsletters: NewsletterRead[];
  state: NewsletterPageState;
  onSubscribe: () => void;
  selectedNewsletters: SelectedNewsletters;
}

const NewsletterActions: FC<Props> = ({
  emails,
  newsletters,
  selectedNewsletters,
  state,
  onSubscribe,
  subscriberId
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let buttonAction = <></>;
  if (emails && newsletters) {
    if (selectedNewsletters.length && !subscriberId) {
      buttonAction = (
        <Button
          variant={"contained"}
          color="success"
          fullWidth
          onClick={() => onSubscribe()}
          sx={{ mt: 3, flex: 3 }}
        >
          {t("text.newsletter_subscribe_first_confirmation")}
        </Button>
      );
    } else if (selectedNewsletters.length) {
      buttonAction = (
        <Button
          variant={"contained"}
          color="primary"
          fullWidth
          onClick={() => onSubscribe()}
          sx={{ mt: 3, flex: 3 }}
        >
          {t("text.newsletter_subscribe")}
        </Button>
      );
    } else {
      buttonAction = (
        <Button
          variant={"contained"}
          color="error"
          fullWidth
          onClick={() => onSubscribe()}
          sx={{ mt: 3, flex: 3 }}
        >
          {t("text.newsletter_unsubscribe")}
        </Button>
      );
    }
  }

  let buttonNavigate = <></>;
  if (state.success || state.fetchSubscriberDataError) {
    buttonNavigate = (
      <Button
        variant={"outlined"}
        color="primary"
        fullWidth
        onClick={() => navigate(t("common:url.login"))}
        sx={{ mt: 3, flex: 1 }}
        startIcon={<Person />}
      >
        {t("common:text.login_title")}
      </Button>
    );
  }

  return (
    <Stack direction="row" sx={{ gap: "14px" }}>
      {buttonAction}
      {buttonNavigate}
    </Stack>
  );
};

export default NewsletterActions;
