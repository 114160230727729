import { Box, Typography } from "@mui/material";
import NewslettersSelector from "./NewslettersSelector";
import { useTranslation } from "react-i18next";
import NewsletterEmailInput from "./NewsletterEmailInput";
import useNewsletterPage from "./useNewsletterPage";
import NewsletterAlerts from "./NewsletterAlerts";
import NewsletterActions from "./NewsletterActions";

const NewsletterPage = () => {
  const { t } = useTranslation();
  const { state, subscriberId, emails, handleUpdateEmail, handleMailChangeConfirmed, newsletters, selectedNewsletters, setSelectedNewsletters, handleSubscribe  } = useNewsletterPage();

  return (
    <>
      <Box
        sx={{
          maxWidth: {
            sm: "100vw",
            md: "70vw",
            lg: "900px",
          },
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          {t("text.newsletter_welcome")}
        </Typography>

        {!state.fetchSubscriberDataError && (
          <>
            <Typography variant="body1" color="text.secondary" gutterBottom>
              {subscriberId
                ? t("text.newsletter_instructions_mail_with_edition")
                : t("text.newsletter_instructions_mail")}
            </Typography>
            <br />
            <NewsletterEmailInput
              value={emails ?? ""}
              onMailChangeConfirmed={handleMailChangeConfirmed}
              onEditNewEmailClick={(newEmail) => handleUpdateEmail(newEmail)}
              hideSubmitButton={!!emails}
              subscriberId={subscriberId}
            />
          </>
        )}
        {emails && newsletters && (
          <>
            <br />
            <Typography variant="body1" color="text.secondary" gutterBottom>
              {t("text.newsletter_instructions_newsletter")}
            </Typography>
            <br />
            <NewslettersSelector
              newsletters={newsletters}
              value={selectedNewsletters}
              onChange={(value) => setSelectedNewsletters(value)}
            />
          </>
        )}

        <NewsletterActions
          onSubscribe={handleSubscribe}
          newsletters={newsletters}
          emails={emails}
          state={state}
          subscriberId={subscriberId}
          selectedNewsletters={selectedNewsletters}/>

        <br/>
        <NewsletterAlerts state={state}/>

      </Box>
      <Box sx={{ mt: "17px", textAlign: "center" }}>
        {!!emails && (
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {t("text.newsletter_mail_locked_explanation", { mail: emails })}
            <a href={t("common:url.newsletter")} target="_blank">
              {" "}
              {t("text.newsletter_mail_locked_link")}
            </a>
          </Typography>
        )}
      </Box>
    </>
  );
};

export default NewsletterPage;
