import Settings from "../settings";
import {
  SUBMIT_ABOUT_INSTALLATION_DATA,
  SUBMIT_ABOUT_YOU_DATA,
  SUBMIT_PAYMENT_DATA,
  SET_ACTIVE_CONTRACT,
  SET_CUPS_VALID,
  SET_CUPS_INVALID,
  SUBMIT_ABOUT_CONTRACT_DATA,
  SUBMIT_ABOUT_GAS_CONTRACT_DATA,
  SUBMIT_CONFIRM_DATA,
  STORE_CUPS_DATA,
  STORE_RESIDENCE_TYPE_DATA,
  STORE_UPLOADED_INVOICES_DATA,
  STORE_SIPS_DATA,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE,
  FETCH_CONTRACTATION_CONFIG_REQUEST,
  RECEIVE_CONTRACTATION_CONFIG,
  SET_LEAD_ID,
  SET_LEAD_STEP_CURRENT,
  SET_LEAD_INDEX_COMPLETED,
  SET_LEAD_ERRORS,
  SET_LEAD_SUBMIT_REQUEST
} from "../constants";
import { createReducer } from "../utils/misc";


const initialState = {
  
  submitting: false,
  leadId: null,
  currentStep: {
    index: 0,
    name : "installation"
  },
  leadsConsent: false,
  leadErrors: {
    badScore: null,
    dniInvalid: null,
    genericError: null
  },
  latestStep: 0,
  completedSteps: {},

  cups: null,
  cupsAvailability: {
    invalid: null,
    reason: ''
  },
  promotionalCode: '',

  address: {
    zipcode: ''
  },
  residenceType: (Settings.newContract?.defaultCnaeCheck ?? true) ? "habitual" : null,
  cnae: null,
  invoice: null,
  comerOrigin: null,
  activeContract: null,
  acceptMorosityVerification: false,

  vat: null,
  company: false,
  name: null,
  surName1: null,
  surName2: null,
  cif: null,
  changeOwner: null,
  vatRepresentante: null,
  nameRepresentante: null,
  surName1Representante: null,
  surName2Representante: null,
  sameInvoiceAddress: true,
  invoiceAddress: {},
  commsLanguage: null,
  email: null,
  mobile: null,

  power: null,
  power2: null,
  power3: null,
  power4: null,
  power5: null,
  power6: null,
  accessTariff: null,
  accessTariffName: '',
  selfcons: false,
  selfconsAttachments: [],
  selfconsCode: '00',

  includeGas: Settings?.dualContractation?.gasIsMandatory ?? false,

  availableProducts: [],
  selectedProduct: null,
  selectedProductName: null,

  iban: null,
  isOwner: false,

  acceptOVConditions: false,
  acceptGdpr: false,
  acceptPublicity: false,

  isConfirming: false,
};

let reducerMap = {
  [SET_LEAD_ID]: (state, payload) => 
    Object.assign({}, state, {
      leadId: payload.leadId,
  }),
  [SET_LEAD_STEP_CURRENT]: (state, payload) =>
    Object.assign({}, state, {
      currentStep: {
        index: payload.index,
        name: payload.name
      }
  }),
  [SET_LEAD_INDEX_COMPLETED]: (state, payload) =>
    Object.assign({}, state, {
      completedSteps: {
        ...state.completedSteps,
        [payload.stepIndex]: true
      },
      latestStep: payload.stepIndex + 1,
      submitting: false
  }),
  [SET_LEAD_SUBMIT_REQUEST]: (state) =>
   Object.assign({}, state, {
    submitting: true,
  }),
  [SET_LEAD_ERRORS]: (state, payload) =>
    Object.assign({}, state, {
      leadErrors: payload
  }),
  [SUBMIT_ABOUT_INSTALLATION_DATA]: (state, payload) =>
    Object.assign({}, state, {
      leadsConsent: payload.leadsConsent,
      cups: payload.cups,
      address: payload.address,
      residenceType: payload.residenceType,
      cnae: payload.cnae,
      invoice: payload.invoice,
      comerOrigin: payload.comerOrigin,
      activeContract: payload.activeContract,
      acceptMorosityVerification: payload.acceptMorosityVerification
    }),
  [SUBMIT_ABOUT_YOU_DATA]: (state, payload) => {
    let newState = {
      ...state,
      vat: payload.vat,
      company: payload.company,
      name: payload.name,
      surName1: payload.surName1,
      surName2: payload.surName2,
      changeOwner: payload.changeOwner,
      vatRepresentante: payload.vatRepresentante,
      nameRepresentante: payload.nameRepresentante,
      surName1Representante: payload.surName1Representante,
      surName2Representante: payload.surName2Representante,
      sameInvoiceAddress: payload.sameInvoiceAddress,
      invoiceAddress: payload.invoiceAddress,
      commsLanguage: payload.commsLanguage,
      email: payload.email,
      mobile: payload.mobile,
      nifFaceup: payload.nifFaceup,
      nifEmpresa: payload.nifEmpresa
    };
    return newState;
  },
  [SET_ACTIVE_CONTRACT]: (state, payload) =>
  Object.assign({}, state, {
    activeContract: payload.activeContract,
  }),
  [SUBMIT_ABOUT_CONTRACT_DATA]: (state, payload) => 
  Object.assign({}, state, {
    power: payload.power,
    power2: payload.power2,
    power3: payload.power3,
    power4: payload.power4,
    power5: payload.power5,
    power6: payload.power6,
    accessTariff: payload.accessTariff,
    selectedProduct: payload.selectedProduct,
    selectedProductName: payload.selectedProductName,
    selfcons: payload.selfcons,
    selfconsAttachments: payload.selfconsAttachments,
    promotionalCode: payload.promotionalCode
  }),
  [SUBMIT_ABOUT_GAS_CONTRACT_DATA]: (state, payload) =>
    Object.assign({}, state, {
      cupsGas: payload.cupsGas,
      comerGasOrigin: payload.comerGasOrigin,
      selectedGasProduct: payload.selectedGasProduct,
    }),
  [SUBMIT_PAYMENT_DATA]: (state, payload) =>
    Object.assign({}, state, {
      iban: payload.iban,
      isOwner: payload.isOwner,
    }),
  [SUBMIT_CONFIRM_DATA]: (state, payload) =>
    Object.assign({}, state, {
      promotionalCode: payload.promotionalCode,
      acceptOVConditions: payload.acceptOVConditions,
      acceptGdpr: payload.acceptGdpr,
      acceptPublicty: payload.acceptPublicty,
    }),
  [NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isConfirming: true,
    }),
  [NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE]: (state, payload) =>
    Object.assign({}, state, {
      confirmResponse: payload.confirmResponse,
      isConfirming: false,
    }),
  [FETCH_CONTRACTATION_CONFIG_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isFetching: true,
    }),
  [RECEIVE_CONTRACTATION_CONFIG]: (state, payload) =>
    Object.assign({}, state, {
      contractationConfig: payload.contractationConfig,
      ...payload.contractationConfig,
      isFetching: false,
    }),
  [STORE_SIPS_DATA]: (state, {sipsData}) => {
    const toStoreSipsData = {
      selfcons: sipsData?.selfcons || false,
      power: sipsData?.power_p1 || null,
      power2: sipsData?.power_p2 || null,
      power3: sipsData?.power_p3 || null,
      power4: sipsData?.power_p4 || null,
      power5: sipsData?.power_p5 || null,
      power6: sipsData?.power_p6 || null,
      accessTariff: sipsData?.access_tariff?.id || null,
      accessTariffName: sipsData?.access_tariff?.name || '',
      address: { zipcode: sipsData?.address?.zipcode },
      selfconsCode: sipsData?.autoconsumo_code || '00',
    };

    const newState = {
      ...state,
      ...toStoreSipsData,
      sipsData: sipsData?.sipsStatus?.code === 200,
    }
    return newState;
  },
};

// I do this to avoid code duplication in the reducerMap.
[SET_CUPS_INVALID, SET_CUPS_VALID].map(
  type => reducerMap[type] = (state, payload) => {
    return {
      ...state,
      cupsAvailability: {
        ...payload
      }
    }
  }
);

[
  STORE_CUPS_DATA,
  STORE_RESIDENCE_TYPE_DATA,
  STORE_UPLOADED_INVOICES_DATA
].map(
  type => reducerMap[type] = (state, payload) => {
    let newState = {
      ...state,
      ...payload
    };
    return newState;
  }
);

export default createReducer(initialState, reducerMap);